<template>
  <div class="mypage">
    <div class="wrapper">
      <div class="sidebar">
        <div
          class="avatar"
          :style="{ backgroundImage: 'url(' + this.avatar_image + ')' }"
        ></div>
        <div class="name">
          <img
            class="flag"
            :src="
              require('@/assets/img/flag/' +
                this.storage.get_user_country() +
                '.svg')
            "
          />
          {{ this.storage.get_user_name() }}
        </div>
        <!-- <div class="grade">Family</div> -->
        <button
          class="button outline red middle customize"
          @click="this.$router.push('/avatar/customize')"
        >
          {{ this.$t("mypage.button_avatar") }}
        </button>

        <button class="button outline middle logout" @click="try_logout">
          {{ this.$t("gnb.logout") }}
        </button>
      </div>
      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "MyPage",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.mypage"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.mypage"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      avatar_image: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    let self = this;

    // 로그인 안되어 있으면 메인 페이지로 이동
    if (!this.storage.is_logged_in()) {
      this.$swal
        .fire({
          text: this.$t("mypage.error_no_login"),
          icon: "error",
        })
        .then(() => {
          self.$router.push("/");
        });
      return;
    }

    this.load_user_avatar();
  },
  methods: {
    load_user_avatar: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data.data;

          self.avatar_image =
            process.env.VUE_APP_API_URL +
            "/resource/get/avatar/user/" +
            self.storage.get_user_id() +
            "/" +
            data.avatar_thumbnail;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    // 로그아웃 버튼 이벤트 핸들러
    try_logout: function (event) {
      event.stopPropagation();
      event.preventDefault();

      let self = this;

      this.$swal
        .fire({
          text: this.$t("auth.confirm_logout"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .post(process.env.VUE_APP_API_URL + "/auth/logout")
              .finally(function () {
                // 토큰 및 사용자 정보 제거하기
                self.storage.clear();
                document.location.href = "/";
              });
          }
        }); //-- swal
    }, //-- try_logout
  },
};
</script>

<style scoped>
.mypage {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 1rem auto;
  padding: 0;

  background: #fff;
}
.mypage > .wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.mypage > .wrapper > .sidebar {
  position: relative;
  width: 320px;
  padding: 1rem;
  border-right: 1px solid #ededed;
}
.mypage > .wrapper > .sidebar > .avatar {
  width: 140px;
  max-width: 80%;
  border-radius: 50%;
  margin: 1rem auto;

  background: #dedede;
  background: #dedede url("../../assets/img/icon_profile.png") no-repeat;
  background-position: center center;
  background-size: cover;
}
.mypage > .wrapper > .sidebar > .avatar::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.mypage > .wrapper > .sidebar > .name {
  position: relative;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}
.mypage > .wrapper > .sidebar > .name > .flag {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  margin: 0 0.8rem 0 0;
  border: 1px solid #dedede;
}
.mypage > .wrapper > .sidebar > .grade {
  font-size: 1rem;
  font-weight: 300;
  color: #808080;
  text-align: center;
}
.mypage > .wrapper > .sidebar > button.customize {
  width: 100%;
  margin-top: 3rem;
  text-transform: uppercase;
}
.mypage > .wrapper > .sidebar > button.logout {
  display: none;
  width: 100%;
  margin-top: 1.2rem;
  text-transform: uppercase;
}

.mypage > .wrapper > .body {
  width: calc(100% - 320px - 1px - 0.1px);
  padding: 1rem 2rem;
}
.mypage > .wrapper > .body :deep(.header) {
  position: relative;
  margin: 0 0 2rem 0;
}
.mypage > .wrapper > .body :deep(.header > h1) {
  padding: 0;
  margin: 0;

  font-size: 1.8rem;
  font-weight: 700;
}
.mypage > .wrapper > .body :deep(.header > .path) {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);

  width: auto;

  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin: 0;
  padding: 0;
}
.mypage > .wrapper > .body :deep(.header > .path > li) {
  position: relative;
  display: inline-block;
  padding: 0 0 0 1rem;
  font-size: 0.875rem;
}
.mypage > .wrapper > .body :deep(.header > .path > li a) {
  color: #808080;
  text-decoration: none;
}
.mypage > .wrapper > .body :deep(.header > .path > li.current a) {
  color: #333;
}
.mypage > .wrapper > .body :deep(.header > .path > li a:hover) {
  text-decoration: underline;
}
.mypage > .wrapper > .body :deep(.header > .path > li:after) {
  content: "/";
  padding-left: 1rem;
  color: #dedede;
}
.mypage > .wrapper > .body :deep(.header > .path > li:last-child:after) {
  content: "";
}

@media (max-width: 800px) {
  .mypage {
    padding: 0;
  }
  .mypage > .wrapper {
    display: block;
    justify-content: flex-start;
    align-items: stretch;
  }
  .mypage > .wrapper > .sidebar {
    width: 100%;
    border-right: none;
  }

  .mypage > .wrapper > .sidebar > button.logout {
    display: block;
  }
  /* .mypage > .wrapper > .sidebar > .avatar {
    width: 140px;
    max-width: 80%;
    border-radius: 50%;
    margin: 1rem auto;

    background: #dedede;
    background: #dedede url("../../assets/img/icon_profile.png") no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .mypage > .wrapper > .sidebar > .avatar::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .mypage > .wrapper > .sidebar > .name {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
  .mypage > .wrapper > .sidebar > .grade {
    font-size: 1rem;
    font-weight: 300;
    color: #808080;
    text-align: center;
  }
  .mypage > .wrapper > .sidebar > button.customize {
    width: 100%;
    margin-top: 3rem;
    text-transform: uppercase;
  } */

  .mypage > .wrapper > .body {
    width: 100%;
    padding: 1rem;
  }
}
</style>
